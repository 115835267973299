var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _c(
        "b-tabs",
        {
          attrs: {
            vertical: "",
            "nav-wrapper-class": "col-3",
            "nav-class": "mt-5",
            lazy: ""
          },
          on: { input: _vm.scrollToTop },
          scopedSlots: _vm._u([
            {
              key: "tabs-start",
              fn: function() {
                return [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.modal-component-form",
                          modifiers: { "modal-component-form": true }
                        }
                      ],
                      staticClass: "green-button-transparent mr-3 mb-3 border-0"
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-2 top--3",
                        attrs: {
                          icon: "plus-circle-fill",
                          "aria-hidden": "true",
                          scale: "1"
                        }
                      }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("formManagement.createFormItem")) +
                          " "
                      )
                    ],
                    1
                  ),
                  _c("modal-add-new-form-item", {
                    staticClass: "d-inline-block mb-3",
                    on: { "create-form-item": _vm.createFormItem }
                  })
                ]
              },
              proxy: true
            },
            {
              key: "empty",
              fn: function() {
                return [
                  _vm.allowedToCreateForms
                    ? _c("div", { staticClass: "mt-5" }, [
                        _c("p", {
                          staticClass: "text-center",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("formManagement.howToFormDesc")
                            )
                          }
                        })
                      ])
                    : _c("div", { staticClass: "mt-5" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("formManagement.emptyForm")) + " "
                        )
                      ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.tabIndex,
            callback: function($$v) {
              _vm.tabIndex = $$v
            },
            expression: "tabIndex"
          }
        },
        _vm._l(_vm.formItems, function(formItem, index) {
          return _c(
            "b-tab",
            {
              key: index,
              attrs: { lazy: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function() {
                      return [_vm._v(" " + _vm._s(formItem.name) + " ")]
                    },
                    proxy: true
                  }
                ],
                null,
                true
              )
            },
            [
              _c("form-item-list-view", {
                staticClass: "mt-5",
                attrs: {
                  userId: _vm.userId,
                  formItem: formItem,
                  formItemId: formItem.form_id,
                  formIndex: index
                },
                on: { changeInFormList: _vm.updateFormItemQuery }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }